<script>
import { Line, mixins } from 'vue-chartjs';

const { reactiveProp } = mixins;

export default {
  extends: Line,

  props: {
    chartData: {
      type: Object,
      default: null
    }
  },

  mixins: [reactiveProp],

  data() {
    return {
      gradient: null
    };
  },

  mounted() {
    this.gradient = this.$refs.canvas
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 450);

    this.gradient.addColorStop(0, 'rgba(77, 208,0, 0.5)');
    this.gradient.addColorStop(0.5, 'rgba(77, 208, 0, 0.25)');
    this.gradient.addColorStop(1, 'rgba(77, 208, 0, 0)');

    this.renderChart(this.chartData, {
      responsive: true,
      maintainAspectRatio: false,
      legend: false,
      scales: {
        xAxes: [
          {
            gridLines: {
              borderDash: [8, 4]
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              fontColor: 'rgba(110,110,110,1)',
              fontStyle: 'bold',
              padding: 10
            }
          }
        ]
      }
    });
  }
};
</script>
