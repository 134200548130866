<template>
  <div class="green-section--mob">
    <GreenBlock>
      <v-container v-if="!loading" class="mobile-header">
        <h1 class="page-title">
          Guten tag, {{ data.user.salutation }} {{ data.user.lastName }}
        </h1>
        <p>Hier können Sie Ihren Zählerstand erfassen.</p>
      </v-container>
    </GreenBlock>

    <v-container v-if="!loading">
      <div class="box-shadow mb-0">
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            height="0"
            width="0"
            class="gradient-bar"
        >
          <defs>
            <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" style="stop-color:#A7D60C;stop-opacity:1"/>
              <stop offset="50%" style="stop-color:#FFDA07;stop-opacity:1"/>
              <stop offset="100%" style="stop-color:#C63A3A;stop-opacity:1"/>
            </linearGradient>
          </defs>
        </svg>
        <v-row>
          <v-col>
            <div
                v-if="!smart"
                class="meter-consumption--description mobile-carousel"
            >
              <VueSlickCarousel :arrows="false" :dots="true">
                <div class="mobile-consumption-info" style="display: flex">
                  <div class="mobile-header-info">
                    <h3 class="section__title">Zählerstandserfassung</h3>
                    <p class="subtitle">
                      Zählernummer:
                      <span class="font-weight-bold">{{
                          data.user.counterNumber
                        }}</span>
                    </p>
                    <p class="font-weight-bold gray-text">
                      Verbleibende Strommenge in Ihrem aktuellen Paket:
                      <span class="red-text"
                      >{{
                          addDotToThousands(data.calculations.restOfPackage)
                        }}
                        kWh</span
                      >
                    </p>
                    <p class="until-date-text">
                      Basierend auf Ihrem bisherigen Verbrauch reicht Ihr
                      aktuelles Paket voraussichtlich bis
                      <span class="red-text">{{
                          getDate(data.calculations.packageEndingDate)
                        }}</span
                      >.
                    </p>
                  </div>
                  <div class="consumption-progress-bar">
                    <round-slider
                        class="consumption-progress"
                        v-model="progress"
                        startAngle="0"
                        width="8"
                        circleShape="half-top"
                        line-cap="round"
                        radius="70"
                        readOnly="true"
                        rangeColor="transparent"
                        pathColor="url(#grad1)"
                    />
                  </div>
                </div>
                <div>
                  <div class="small-mobile-chart">
                    <h3 class="page-subtitle">
                      Monatlicher <br/>Stromverbrauch
                    </h3>
                    <div class="column-chart">
                      <div class="unit-measurement">In kWh</div>
                      <div class="custom--bar" id="chartheight">
                        <div class="bar-column-1"></div>
                        <div class="bar-column-2"></div>
                        <div class="bar-column-3"></div>
                        <div class="bar-column-4"></div>
                        <div class="bar-column-5"></div>
                      </div>
                      <div class="custom-bar-date">
                        <div class="bar-date-1"></div>
                        <div class="bar-date-2"></div>
                        <div class="bar-date-3"></div>
                        <div class="bar-date-4"></div>
                        <div class="bar-date-5"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="more-info--block">
                    <img src="../../../assets/images/mobile-bg.png" alt=""/>
                    <p>
                      Zählerstände automatisch melden und zusätzliche
                      Informationen freischalten.
                    </p>
                    <a :href="linkToSmartMeter" target="_blank">
                      <button class="btn" v-on:click="newTabOpen()">
                        SmartMeter bestellen
                      </button>
                    </a>
                  </div>
                </div>
              </VueSlickCarousel>
            </div>
            <div v-if="smart" class="meter-consumption--description smart-meter-consumption--description">
              <div style="display: flex; position: relative">
                <div>
                  <h3 class="page-subtitle">Zählerstandserfassung</h3>
                  <p class="subtitle">
                    Zählernummer:
                    <span class="font-weight-bold">{{ getMeterId() }}</span>
                  </p>
                  <p class="font-weight-bold gray-text">
                    Verbleibende Strommenge in Ihrem aktuellen Paket:
                    <span class="red-text"
                    >{{
                        addDotToThousands(data.calculations.restOfPackage)
                      }}
                      kWh</span
                    >
                  </p>
                  <p class="until-date-text">
                    Basierend auf Ihrem bisherigen Verbrauch reicht Ihr
                    aktuelles Paket voraussichtlich bis
                    <span class="red-text">{{
                        getDate(data.calculations.packageEndingDate)
                      }}</span
                    >.
                  </p>
                </div>
                <div class="consumption-progress-bar">
                  <round-slider
                      class="consumption-progress"
                      v-model="progress"
                      startAngle="0"
                      width="8"
                      circleShape="half-top"
                      line-cap="round"
                      radius="70"
                      readOnly="true"
                      rangeColor="transparent"
                      pathColor="url(#grad1)"
                  />
                </div>
              </div>
            </div>
            <div class="green-border" v-else>
              <h3 class="section__title desktop-show">Zählerstandserfassung</h3>
              <p class="subtitle desktop-show">
                Zählernummer:
                <span class="font-weight-bold">{{
                    data.user.counterNumber
                  }}</span>
              </p>
              <div v-if="data.can_submit" class="form-reading">
                <p class="font-weight-bold" v-if="getReadingListLength === 1">
                  Bitte geben Sie Ihren aktuellen Zählerstand für die
                  Erstmeldung ein.
                </p>
                <p class="font-weight-bold" v-else>
                  Bitte geben Sie Ihren Zählerstand ein:
                </p>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <div class="smarmeter-info__block">
                    <div class="date-picker">
                      <v-dialog
                          ref="dialog"
                          v-model="modal"
                          :return-value.sync="readingAt"
                          persistent
                          width="320px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="computedDateFormatted"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="readingAt"
                            no-title
                            scrollable
                            class="p-0"
                            locale="de"
                            :weekday-format="getWeekDay"
                            :allowed-dates="allowedDates"
                            :first-day-of-week="1"
                            :min="this.data.emailSentAt"
                            :max="currentDate"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                              class="cancel-date-btn "
                              text
                              color="primary"
                              @click="modal = false"
                          >
                            Zurück
                          </v-btn>
                          <v-btn
                              class="submit-date-btn btn"
                              text
                              color="primary"
                              @click="$refs.dialog.save(readingAt)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </div>
                    <v-text-field
                        class="meter-value-input"
                        v-model="meterValue"
                        v-on:keydown="keyhandler"
                        placeholder="Zählerstand"
                        :rules="[
                        (v) => !!v || 'Der Zählerstand ist ein Pflichtfeld',
                        (v) =>
                          (v && convertToNumber(v) > getLastSubmittedReading) ||
                          'Der Zählerstand muss größer als bei der letzten Meldung sein.',
                      ]"
                        outlined
                        required
                    >
                    </v-text-field>
                  </div>
                  <v-btn
                      class=" btn"
                      color="warning"
                      rounded
                      block
                      @click="validate"
                  >
                    Absenden
                  </v-btn>
                  <v-dialog v-model="dialog" width="500">
                    <v-card class="accept-dialog">
                      <v-card-title class="dialog-title">
                        Wichtiger Hinweis
                      </v-card-title>

                      <v-card-text>
                        <p class="user-id-info">
                          Zählernummer:
                          <span>{{ data.user.counterNumber }}</span>
                        </p>
                        <p>
                          Sie melden hiermit Ihren Zählerstand für
                          <span class="font-weight-bold">{{
                              getDateWithDay(this.readingAt)
                            }}</span>
                          mit:
                        </p>
                        <p class="consumption-number">
                          {{ addDotToThousands(convertToNumber(meterValue)) }}
                          kWh
                        </p>
                        <p>
                          Bitte überprüfen Sie noch einmal, dass dies der
                          korrekte Wert ist. Diese Daten können später nicht
                          mehr korrigiert werden.
                        </p>
                      </v-card-text>

                      <v-card-actions class="flex-column">
                        <v-btn
                            class="mb-3 btn"
                            color="warning"
                            rounded
                            :loading="submitLoading"
                            @click="submitMeterValue"
                        >
                          Der Zählerstand ist korrekt
                        </v-btn>
                        <v-btn
                            color="warning cancel-consumption-btn"
                            text
                            @click="dialog = false"
                        >
                          Ich möchte den Zählerstand korrigieren
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-form>
              </div>
              <div v-else class="success-submit--consumption">
                <p class="font-weight-bold" v-if="checkFirstValueEmpty">
                  Sie haben Ihren Zählerstand für den
                  {{
                    getDateWithDay(
                        data.meter_reading[Object.keys(data.meter_reading)[1]]
                            .readingAt
                    )
                  }}
                  erfolgreich gemeldet.
                </p>
                <p class="font-weight-bold" v-else>
                  Sie haben Ihren Zählerstand für den
                  {{
                    getDateWithDay(
                        data.meter_reading[Object.keys(data.meter_reading)[0]]
                            .readingAt
                    )
                  }}
                  erfolgreich gemeldet.
                </p>
                <v-divider></v-divider>
                <p>
                  Brauchen Sie Hilfe? Schreiben Sie bitte an
                  <a href="mailto:strompakete@eprimo.de"
                  >strompakete@eprimo.de</a
                  >
                </p>
              </div>
            </div>
            <div class="consumption-table">
              <v-simple-table class="mt-3">
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left">
                      Monat
                    </th>
                    <th class="text-left">
                      Zählerstand
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="(value, token) in data.meter_reading"
                      :key="token"
                      class="table-row"
                  >
                    <template v-if="!isMeterChangeDate(value.month)">
                      <td class="font-weight-bold">
                        {{
                          value.month === "Erstmeldung"
                              ? "Erstmeldung"
                              : value.readingAt
                                  ? getDateWithDay(value.readingAt)
                                  : getDate(value.month)
                        }}
                      </td>
                      <td class="font-weight-bold meter-value-cell">
                        {{
                          roundMeterValue(value.meterValue, value.estimated) ||
                          "-"
                        }}
                      </td>
                    </template>
                    <template v-else>
                      <td class="font-weight-bold meter-change-row" @click="toggleDropdown($event)">
                      <span class="meter-change-row_click">
                                            <span>{{
                                                value.month === "Erstmeldung"
                                                    ? "Erstmeldung"
                                                    : value.readingAt
                                                        ? getDateWithDay(value.readingAt)
                                                        : getDate(value.month)
                                              }}
                        </span>
                      <img src="../../../assets/images/icons/green-circle.svg" alt="green-circle">
                      <img src="../../../assets/images/icons/down-arrow.svg" alt="arrow" class="dropdown-arrow">
                      </span>
                      </td>
                      <td class="font-weight-bold meter-change-row meter-value-cell" v-if="getNewMeterValue(value.month)">
                        {{
                          roundMeterValue(value.meterValue, value.estimated) ||
                          "-"
                        }}
                      </td>
                      <td class="font-weight-bold meter-change-row meter-value-cell" v-else>-</td>
                      <td class="meter-change-info-text" id="additional-meter-info">
                        Wir haben Ihren Zähler mit der Zählernummer {{ data.meterChangeData.oldMeterId }} am {{ data.meterChangeData.dateOfChange }} ausgetauscht. <br>
                        Der abschliessende Ablesung des alten Zählers betrug <span class="font-weight-bold">{{ data.meterChangeData.oldMeterFinalReading }} kWh</span>. <br>
                        Ihr neuer Zähler hat die Zählernummer <span class="font-weight-bold">{{ replaceDigitsByAsterisk(data.meterChangeData.newMeterId) }}</span>.
                      </td>
                    </template>
                  </tr>
                  <template v-if="isMeterChangeFirst()">
                    <tr class="table-row">
                      <td class="font-weight-bold meter-change-row" @click="toggleDropdown($event)">
                      <span class="meter-change-row_click">
                        <span>{{ getMeterChangeMonth() }}</span>
                      <img src="../../../assets/images/icons/green-circle.svg" alt="green-circle">
                      <img src="../../../assets/images/icons/down-arrow.svg" alt="arrow" class="dropdown-arrow">
                      </span>
                      </td>
                      <td class="font-weight-bold meter-change-row meter-value-cell">-</td>
                      <td class="meter-change-info-text" id="additional-meter-info2">
                        Wir haben Ihren Zähler mit der Zählernummer {{ data.meterChangeData.oldMeterId }} am {{ data.meterChangeData.dateOfChange }} ausgetauscht. <br>
                        Der abschliessende Ablesung des alten Zählers betrug <span class="font-weight-bold">{{ data.meterChangeData.oldMeterFinalReading }} kWh</span>. <br>
                        Ihr neuer Zähler hat die Zählernummer <span class="font-weight-bold">{{ replaceDigitsByAsterisk(data.meterChangeData.newMeterId) }}</span>.
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </template>
              </v-simple-table>
              <p class="hint">*Werte in Klammern sind geschätzt</p>
            </div>
          </v-col>
          <v-col>
            <div v-if="smart" class="meter-consumption--description">
              <div id="chart">
                <div>
                  <h3 class="page-subtitle">Meinen Stromverbrauch</h3>
                  <!--                   {{this.smartDate}} {{this.smartValue}}-->
                  <div class="consumption-from-graph-result">
                    <p class="font-weight-bold gray-tex">Monatsverbrauch im {{ currentMonth }}</p>
                    <div>
                      <p class="big-gray-text"><span>{{ sumMonthGraph }}</span> kWh</p>
                    </div>
                  </div>
                  <div class="next-upadate-time-block">
                    <p>
                      Nächstes Datenupdate:
                      <span class="font-weight-bold">
                      {{ getNextUpdateMeterTime(data.smart.next_data_update) }}
                    </span>
                    </p>
                  </div>
                  <span class="yaxis-label">kWh</span>
                  <div class="chart-smartmeter-block">
                    <chart :chart-data="chartData" :height="300"/>
                  </div>
                  <div class="graph-xaxis-block">
                    <span class="graph-arrows text-left">
                      <button v-if="monthMinDiapason()" v-on:click="changeMonthGraph('prev')"><img src="../../../assets/images/icons/left-arrow.svg" alt=""></button>
                    </span>
                    <div class="graph-labels">
                      <span>01.{{ String(monthGraph).padStart(2, "0") }}</span>
                      <span>15.{{ String(monthGraph).padStart(2, "0") }}</span>
                      <span>{{ daysInMonth }}.{{ String(monthGraph).padStart(2, "0") }}</span>
                    </div>
                    <span class="graph-arrows text-right">
                      <button v-if="monthMaxDiapason()" v-on:click="changeMonthGraph('next')"><img src="../../../assets/images/icons/right-arrow.svg" alt=""></button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="meter-consumption--description desktop-show">
              <div style="display: flex;">
                <div>
                  <h3 class="page-subtitle">Verbleibende Strommenge</h3>
                  <p class="font-weight-bold gray-text">
                    Verbleibende Strommenge in Ihrem aktuellen Paket:
                    <span class="red-text"
                    >{{
                        addDotToThousands(data.calculations.restOfPackage)
                      }}
                      kWh</span
                    >
                  </p>
                  <p class="until-date-text">
                    Basierend auf Ihrem bisherigen Verbrauch reicht Ihr
                    aktuelles Paket voraussichtlich bis
                    <span class="red-text">{{
                        getDate(data.calculations.packageEndingDate)
                      }}</span
                    >.
                  </p>
                </div>
                <div class="consumption-progress-bar">
                  <round-slider
                      class="consumption-progress"
                      v-model="progress"
                      startAngle="0"
                      width="8"
                      circleShape="half-top"
                      line-cap="round"
                      radius="70"
                      readOnly="true"
                      rangeColor="transparent"
                      pathColor="url(#grad1)"
                  />
                </div>
              </div>
              <v-divider class="thin-devider"></v-divider>

              <h3 class="page-subtitle">Monatlicher Stromverbrauch</h3>
              <div class="column-chart">
                <div class="unit-measurement">In kWh</div>
                <div class="custom--bar">
                  <div class="bar-column-1"></div>
                  <div class="bar-column-2"></div>
                  <div class="bar-column-3"></div>
                  <div class="bar-column-4"></div>
                  <div class="bar-column-5"></div>
                </div>
                <div class="custom-bar-date">
                  <div class="bar-date-1"></div>
                  <div class="bar-date-2"></div>
                  <div class="bar-date-3"></div>
                  <div class="bar-date-4"></div>
                  <div class="bar-date-5"></div>
                </div>
              </div>

              <v-divider></v-divider>

              <div class="more-info--block">
                <img src="../../../assets/images/diagram-bg.png" alt=""/>
                <p>
                  Zählerstände automatisch melden und zusätzliche Informationen
                  freischalten.
                </p>
                <a :href="linkToSmartMeter" target="_blank">
                  <button class="btn">SmartMeter bestellen</button>
                </a>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import {Line} from 'vue-chartjs'

import GreenBlock from "@/components/GreenBlock";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import Chart from "@/components/Chart";

const daysOfWeek = ["SO", "MO", "DI", "MI", "DO", "FR", "SA"];
const month = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];

export default {
  name: "Input",
  extends: Line,
  components: {
    GreenBlock,
    VueSlickCarousel,
    Chart
  },
  data() {
    return {
      smartDate: [],
      smartValue: [],
      monthGraph: 1,
      yearGraph: 0,
      daysInMonth: 0,
      sumMonthGraph: 0,
      chartData: null,
      gradient: null,
      currentMonth: '',
      currentYearFirstMonthGraph: 1,

      consumptions: [],
      token: this.$route.params.token,
      data: null,
      loading: true,
      submitLoading: false,
      meterValue: null,
      dialog: false,
      progress: 90,
      modal: false,
      readingAt: new Date().toISOString().substr(0, 10),
      dot: {size: 10, width: "5px", backgroundColor: "red"},
      valid: true,
      smart: false
    };
  },
  computed: {
    getReadingListLength() {
      return Object.keys(this.data.meter_reading).length;
    },
    checkFirstValueEmpty() {
      let keys = Object.keys(this.data.meter_reading);
      return !this.data.meter_reading[keys[0]].meterValue;
    },
    getLastSubmittedReading() {
      let len = Object.keys(this.data.meter_reading).length;
      if (len > 1) {
        for (let i = 1; i < len; i++) {
          if (
              this.data.meter_reading[Object.keys(this.data.meter_reading)[i]]
                  .meterValue !== 0
          )
            return this.data.meter_reading[
                Object.keys(this.data.meter_reading)[i]
                ].meterValue;
        }
      }
      return this.data.meter_reading[Object.keys(this.data.meter_reading)[0]]
          .meterValue;
    },
    linkToSmartMeter() {
      return location.origin + "/smartmeter/" + this.data.smartMeterToken;
    },
    date() {
      return this.data.month
          ? this.data.month + " " + this.data.year
          : "Erstmeldung";
    },
    computedDateFormatted() {
      return this.formatDate(this.readingAt);
    },
    currentDate() {
      let today = new Date();
      return (
          String(today.getFullYear()) +
          "-" +
          String(today.getMonth() + 1).padStart(2, "0") +
          "-" +
          String(today.getDate()).padStart(2, "0")
      );
    }
  },
  methods: {
    isMeterChangeDate(val) {
      if (this.data.meterChangeData) {
        let changeMonth = Number((this.data.meterChangeData.dateOfChange).split(".")[1]);
        let changeYear = Number((this.data.meterChangeData.dateOfChange).split(".")[2]);
        let currentMonth = Number(val.split("-")[1]);
        let currentYear = Number(val.split("-")[0]);
        if (currentMonth === changeMonth && changeYear === currentYear) {
          return true;
        }
      }
      return false;
    },
    isMeterChangeFirst() {
      let keys = Object.keys(this.data.meter_reading);
      let firstDate = this.data.meter_reading[keys[keys.length - 1]].readingAt
      if (this.data.meterChangeData) {
        let changeMonth = this.data.meterChangeData.dateOfChange;
        changeMonth = new Date(changeMonth.split(".")[2] + '-' + changeMonth.split(".")[1] + '-01');
        firstDate = new Date(firstDate.split("-")[0] + '-' + firstDate.split("-")[1] + '-01');
        if (changeMonth.getTime() < firstDate.getTime()) {
          return true;
        }
      }
      return false;
    },
    getMeterChangeMonth() {
      let day = new Date(this.data.meterChangeData.dateOfChange.split(".")[2], this.data.meterChangeData.dateOfChange.split(".")[1], 0).getDate()
      return day + '. ' + month[this.data.meterChangeData.dateOfChange.split(".")[1] - 1] + ' ' + this.data.meterChangeData.dateOfChange.split(".")[2];
    },
    replaceDigitsByAsterisk(val) {
      return val.slice(0, -5).replace(/[0-9]|[A-Z]/g, "*") + val.slice(-5);
    },
    getMeterId() {
      let id = this.data.user.counterNumber;
      if (this.data.meterChangeData.newMeterId) {
        id = this.replaceDigitsByAsterisk(this.data.meterChangeData.newMeterId)
      }
      return id;
    },
    getNewMeterValue(val) {
      if (this.data.meterChangeData) {
        let changeMonth = this.data.meterChangeData.dateOfChange;
        changeMonth = new Date(changeMonth.split(".")[2] + '-' + changeMonth.split(".")[1] + '-01');
        val = new Date(val.split("-")[0] + '-' + val.split("-")[1] + '-01');
        if (changeMonth.getTime() === val.getTime()) {
          return true;
        }
      }
      return false;
    },
    toggleDropdown(event) {
      event.currentTarget.parentElement.classList.toggle('is-active');
      var height = document.getElementById('additional-meter-info');
      if (height === null) height = document.getElementById('additional-meter-info2');
      height = height.offsetHeight;
      let column = document.getElementsByClassName("meter-change-row");
      for (let j = 0; j < column.length; j++) {
        column[j].style.height = height + 40 + "px";
      }
    },
    getChartValue() {
      let valueArray = this.data.smart.month.items.map(item => item.value),
          dateArray = this.data.smart.month.items.map(item => item.date);
      let sumMonth = 0, indexMonth = 0, indexDay = 0, indexYear = 0, currentYear = new Date().getFullYear();
      this.daysInMonth = new Date(this.yearGraph, this.monthGraph, 0).getDate();
      let dateMonthArray = new Array(this.daysInMonth).fill(''),
          valueMonthArray = new Array(this.daysInMonth).fill(0);
      this.currentMonth = month[this.monthGraph - 1];
      for (let i = dateArray.length - 1; i >= 0; i--) {
        indexMonth = Number(dateArray[i].split("-")[1]);
        indexYear = Number(dateArray[i].split("-")[0]);
        if (indexYear === currentYear) {
          this.currentYearFirstMonthGraph = indexMonth;
          if (indexMonth === this.monthGraph) {
            indexDay = Number(dateArray[i].split("-")[2]);
            valueMonthArray[indexDay - 1] = valueArray[i];
            sumMonth += valueArray[i];
            // dateMonthArray[indexDay-1] = dateArray[i];
          }
        } else break;
      }

      this.sumMonthGraph = Number((sumMonth).toFixed(0))
      this.smartDate = dateMonthArray
      this.smartValue = valueMonthArray

      this.chartData = {
        labels: dateMonthArray,
        datasets: [
          {
            borderColor: "#4DD000",
            borderWidth: 1,
            backgroundColor: "#4DD000",
            lineTension: 0,
            pointRadius: 0,
            data: valueMonthArray
          }
        ]
      };

      // this.smartDate = this.data.smart.month.items.map(item => item.date);
      // this.smartValue = this.data.smart.month.items.map(item => item.value);

    },

    changeMonthGraph(val) {
      if (val === 'prev') {
        this.monthGraph -= 1;
      } else this.monthGraph += 1;
      this.getChartValue();
    },
    getMonthGraph() {
      let dateArray = this.data.smart.month.items.map(item => item.date);
      this.monthGraph = Number(dateArray[dateArray.length - 1].split("-")[1]);
      this.yearGraph = Number(dateArray[dateArray.length - 1].split("-")[0]);
    },
    monthMinDiapason() {
      return this.monthGraph > this.currentYearFirstMonthGraph;
    },
    monthMaxDiapason() {
      let dateArray = this.data.smart.month.items.map(item => item.date);
      let lastMonth = Number(dateArray[dateArray.length - 1].split("-")[1]);
      return this.monthGraph < lastMonth;
    },
    getNextUpdateMeterTime(val) {
      var date = new Date(val * 1000);
      return (
          String(date.getHours()).padStart(2, "0") +
          ":" +
          String(date.getMinutes()).padStart(2, "0")
      );
    },
    keyhandler: function(event) {
      if (event.key === "." || event.key === " ") {
        event.preventDefault();
      }
    },
    newTabOpen() {
      window.open(this.linkToSmartMeter, "_blank");
    },
    convertToNumber(val) {
      if (val) {
        return Number(val.replace(/,/g, "."));
      }
    },
    roundMeterValue(val, est) {
      if (val) {
        let value = this.addDotToThousands(val);
        if (est) value = "(" + value + ")";
        return value;
      }
    },
    addDotToThousands(val) {
      val = Math.round(val);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    consumptionsValues() {
      var today = new Date();
      let keys = Object.keys(this.data.meter_reading);
      let lastEmailDate = this.data.meter_reading[keys[0]].month;
      if (lastEmailDate === "Erstmeldung")
        lastEmailDate = new Date(
            today.getFullYear() +
            "-" +
            String(today.getMonth() + 1).padStart(2, "0")
        );
      else lastEmailDate = new Date(this.data.meter_reading[keys[0]].month);
      let arrayDates = [],
          arrayConsumption = [0, 0, 0, 0, 0],
          arrayMeterValues = [];
      for (let i = 0, len = keys.length; i < len; i++) {
        arrayMeterValues[i] = Math.round(
            this.data.meter_reading[keys[i]].meterValue
        );
        if (this.data.meter_reading[keys[i]].month === "Erstmeldung")
          arrayDates[i] =
              today.getFullYear() + "-" + String(today.getMonth() + 1);
        else
          arrayDates[i] = this.data.meter_reading[keys[i]].month.replace(
              /-0+/g,
              "-"
          );
        arrayDates[i] =
            arrayDates[i].split("-")[1] + "/" + arrayDates[i].split("-")[0];
        if (i > 0) arrayMeterValues[i - 1] -= arrayMeterValues[i];
        if (arrayMeterValues[i - 1] < 0) arrayMeterValues[i - 1] = 0;
        if (this.data.meter_reading[keys[i]].estimated)
          arrayMeterValues[i - 1] = "(" + arrayMeterValues[i - 1] + ")";
      }
      if (keys.length !== 1) arrayDates.pop();
      arrayDates.reverse();
      arrayMeterValues.pop();
      arrayMeterValues.reverse();
      if (arrayMeterValues.length < 5) {
        for (let i = 0; i < arrayMeterValues.length; i++) {
          arrayConsumption[i] = arrayMeterValues[i];
        }
        for (let i = arrayDates.length; i < 5; i++) {
          lastEmailDate = new Date(
              arrayDates[i - 1].split("/")[1],
              arrayDates[i - 1].split("/")[0],
              1
          );
          arrayDates[i] =
              String(lastEmailDate.getMonth() + 1) +
              "/" +
              lastEmailDate.getFullYear();
        }
      } else {
        arrayConsumption = arrayMeterValues.slice(
            Math.max(arrayMeterValues.length - 5, 0)
        );
        arrayDates = arrayDates.slice(Math.max(arrayDates.length - 5, 0));
      }
      let percent = 0;
      let intElemOffsetHeight = document.getElementById("chartheight")
          .offsetHeight;
      let indexes = [];
      for (let i = 1; i < 6; i++) {
        if (typeof arrayConsumption[i - 1] === "string") {
          arrayConsumption[i - 1] = Number(
              arrayConsumption[i - 1].replace(/[()]/g, "")
          );
          indexes.push(i - 1);
        }
      }
      let max = Math.max(...arrayConsumption);
      for (let i = 1; i < 6; i++) {
        if (max !== 0) percent = (arrayConsumption[i - 1] * 100) / max;
        if (intElemOffsetHeight !== 0) percent = (75 * percent) / 100;
        let column = document.getElementsByClassName("bar-column-" + i);
        let date = document.getElementsByClassName("bar-date-" + i);
        if (percent !== 0) {
          if (percent <= 15) {
            for (let j = 0; j < column.length; j++) {
              column[j].style.height = "15px";
              let value = this.addDotToThousands(arrayConsumption[i - 1]);
              if (indexes.includes(i) || indexes.includes(i - 1))
                value = "(" + value + ")";
              column[j].innerHTML = value;
            }
          } else {
            for (let j = 0; j < column.length; j++) {
              column[j].style.height = percent + "px";
              let value = this.addDotToThousands(arrayConsumption[i - 1]);
              if (indexes.includes(i) || indexes.includes(i - 1))
                value = "(" + value + ")";
              column[j].innerHTML = value;
            }
          }
        }
        for (let j = 0; j < column.length; j++) {
          date[j].innerHTML = arrayDates[i - 1];
        }
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    getDate(timestamp) {
      const monthNames = [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember"
      ];
      const date = new Date(timestamp);

      return monthNames[date.getMonth()] + " " + date.getFullYear();
    },
    getDateWithDay(timestamp) {
      const date = new Date(timestamp);
      let x = this.getDate(timestamp);
      return date.getDate() + ". " + x;
    },
    allowedDates: (val) => parseInt(val.split("-")[2], 10) % 1 === 0,
    getWeekDay(date) {
      let i = new Date(date).getDay(date);
      return daysOfWeek[i];
    },
    validate() {
      this.$refs.form.validate() && (this.dialog = true);
    },
    getRotate() {
      let kwhQuantity = this.data.user.package.kwhQuantityAll
      if (kwhQuantity === null) kwhQuantity = this.data.user.package.kwhQuantity
      let x = ((kwhQuantity - this.data.calculations.restOfPackage) * 100) / kwhQuantity;
      let y = (180 * x) / 100;
      y = -90 - Math.round((y + Number.EPSILON) * 10) / 10;
      var style = document.createElement("style");
      var style1 = document.createElement("style");
      style.innerHTML =
          ".rs-handle:before {" + "transform: rotate(" + y + "deg);" + "}";
      style1.innerHTML =
          ".meter-consumption--description .gray-text span.red-text," +
          ".meter-consumption--description .until-date-text span.red-text {" +
          "color: #CA4635;" +
          "}";
      var ref = document.querySelector("script");
      if (x > 80) ref.parentNode.insertBefore(style1, ref);
      ref.parentNode.insertBefore(style, ref);
    },
    submitMeterValue() {
      this.submitLoading = true;
      fetch(process.env.VUE_APP_BACKEND_BASE_URL + "meter_reading", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          token: this.token,
          meterValue: this.convertToNumber(this.meterValue),
          readingAt: this.readingAt
        })
      })
          .then((res) => res.json())
          .then((data) => {
            this.submitLoading = false;
            if (data.success) {
              this.data["meter_reading"] = data.meter_reading;
              this.data["calculations"] = data.calculations;
              this.data["can_submit"] = false;
              this.smart = data.smart;
              this.progress =
                  this.data.calculations.percentageOfPackageConsumption * 100;
              this.consumptionsValues();
              this.getRotate();
            } else {
              console.log("error");
            }
          });
    }
  },
  mounted() {
    fetch(process.env.VUE_APP_BACKEND_BASE_URL + "meter_reading/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: this.token
      })
    })
        .then((res) => res.json())
        .then((data) => {
          this.loading = false;
          if (data.success) {
            this.data = data.data;
            this.smart = data.smart;
            if (this.smart) {
              this.getMonthGraph()
              this.getChartValue()
            } else {
              this.$nextTick(() => {
                this.consumptionsValues();
              });
            }
            this.progress =
                this.data.calculations.percentageOfPackageConsumption * 100;
            this.getRotate();
          } else {
            this.$router.push({
              name: "meter_reading_login",
              params: {
                uuidProp: this.token,
                errorProp: true
              }
            });
          }
        });
  }
};
</script>

<style lang="scss" scoped>
.v-sheet--offset {
  top: -24px;
  position: relative;
}

.smarmeter-info__block {
  display: flex;
}

.col {
  padding-top: 0;
}

.green-border {
  border: #74c72a 2px solid;
  border-radius: 4px;
  padding: 9px 15px 10px;
}

.more-info--block {
  position: relative;
  width: 100%;
  text-align: center;
  height: 150px;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.more-info--block img {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
}

p.hint {
  font-size: 12px;
  font-weight: 600;
  margin: 15px 20px 0;
}

h3.section__title {
  margin-bottom: 0;
}

p.subtitle {
  font-size: 12px;
  line-height: 10px;
}

.form-reading {
  p {
    font-size: 15px;
    line-height: 1.2;
  }

  .btn {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

.consumption-progress-bar {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.consumption-progress {
  transform: rotate(90deg);
  margin-right: -20px;
}

.v-application .success-submit--consumption {
  .font-weight-bold {
    font-size: 16px;
  }

  hr {
    width: 100px;
  }

  a {
    color: #77c726;
    text-decoration: underline;
    font-weight: bold;
  }
}

.mobile-carousel {
  display: none;
}

.green-section {
  padding: 20px 0 275px;
  margin-bottom: -263px;
}

.gradient-bar {
  position: absolute;
}

.mobile-header-info {
  margin-left: 5px;
  margin-bottom: 25px;
}

.mobile-consumption-info {
  display: flex;
  position: relative;
}

.custom--bar {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  position: relative;

  &:before {
    content: "";
    width: 100%;
    height: 4px;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #c6c6c6;
    z-index: 1;
  }

  div {
    background-color: $green;
    background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from($green-1),
            to(rgba(255, 255, 255, 0))
    );
    background-image: -o-linear-gradient(top, $green-1, rgba(255, 255, 255, 0));
    background-image: linear-gradient(180deg, $green-1, rgba(255, 255, 255, 0));
    background-size: cover;
    background-repeat: repeat-x;
    width: 54px;

    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    font-size: 13px;
    height: 4px;
    z-index: 2;
  }
}

.column-chart {
  position: relative;
  padding: 0 30px;

  .unit-measurement {
    position: absolute;
    right: 0;
    top: -15px;
    font-size: 10px;
    font-weight: bold;
    color: rgba(110, 110, 110, 0.5);
  }
}

.custom-bar-date {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  position: relative;

  div {
    color: #6e6e6e;
    font-weight: bold;
    font-size: 16px;
    margin-left: 5px;
  }
}

.mobile-show {
  display: none;
}

@media (max-width: 768px) {
  .green-section--mob {
    padding: 10px 0 20px;
    background-color: $green-1;
    background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from($green),
            to(rgba(255, 255, 255, 0))
    );
    background-image: -o-linear-gradient(top, $green, rgba(255, 255, 255, 0));
    background-image: linear-gradient(180deg, $green, rgba(255, 255, 255, 0));
    background-size: cover;
    background-repeat: repeat-x;
    position: relative;
    margin-bottom: 10px;

    &:before {
      content: "";
      background-image: url("~@/assets/images/bg-curve-1.png");
      height: 3vw;
      position: absolute;
      bottom: -0.0625rem;
      display: block;
      width: 100%;
      background-position: top;
      background-size: cover;
      z-index: 0;
    }
  }

  .green-section--mob .green-section {
    background-image: none;
    background-color: transparent;
    margin-bottom: 10px;

    &:before {
      display: none;
    }
  }

  .mobile-carousel {
    display: block;
    margin-bottom: 10px;
  }

  .mobile-header {
    .page-title {
      font-size: 20px;
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
    }
  }

  .meter-consumption--description .page-subtitle {
    margin-top: 0;
  }

  .desktop-show {
    display: none;
  }

  .mobile-show {
    display: block;
  }

  .consumption-progress-bar {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -4px;
  }

  .column-chart {
    padding: 0;
  }

  .custom--bar {
    &:before {
      height: 2px;
    }

    div {
      width: 45px;
      height: 2px;
      font-size: 10px;
    }
  }

  .custom-bar-date {
    div {
      font-size: 11px;
      margin-left: 0;
    }
  }

  .consumption-table tbody {
    height: 165px;
  }

  .consumption-table th:first-child,
  .consumption-table td:first-child {
    width: 180px;
  }

  .consumption-table th:last-child,
  .consumption-table td:last-child {
    width: 85px;
  }

  #chartheight {
    height: 75px;
    padding: 0 15px;
  }

  .meter-consumption--description .page-subtitle {
    line-height: 20px;
  }

  .custom-bar-date {
    padding: 0 15px;
  }
}

@media (max-width: 600px) {
  .section__title {
    letter-spacing: 0;
  }

  .more-info--block {
    p {
      padding: 0 10%;
    }

    img {
      height: 100%;
    }
  }

  .v-input {
    font-size: 16px !important;
  }

  .more-info--block {
    height: 140px;
  }

  .v-dialog > .v-card > .v-card__title {
    font-size: 18px;
  }

  .btn:not(.popover-email) {
    font-size: 16px;
    line-height: 26px;
  }

  .box-shadow {
    padding: 14px;
  }

  .meter-consumption--description .gray-text {
    line-height: 17px;
    letter-spacing: 0.01em;
    margin-bottom: 15px;
    margin-right: 20%;
  }

  .column-chart {
    margin-bottom: 15px;
  }

  .mobile-header-info .subtitle span {
    letter-spacing: 0;
  }

  .until-date-text {
    margin-right: 20%;
  }
}

@media (max-width: 400px) {
  .mobile-header-info p.subtitle {
    line-height: 14px;
    margin-bottom: 10px;

    span {
      font-size: 11px;
    }
  }

  .until-date-text {
    line-height: 15px;
    margin-bottom: 0;
  }
}

@media (max-width: 330px) {
  .consumption-progress-bar {
    top: 64%;
  }

  .mobile-header-info {
    p {
      font-size: 12px;

      &.until-date-text {
        font-size: 11px;
      }

      &.subtitle {
        margin-bottom: 28px;
      }
    }
  }

  .custom--bar {
    div {
      width: 40px;
    }
  }

  .date-picker {
    width: 100%;
  }

  .form-reading {
    p {
      font-size: 12px;
    }

    .smarmeter-info__block {
      flex-wrap: wrap;
    }
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 12px;
  }

  .btn:not(.popover-email) {
    font-size: 14px;
  }

  .small-mobile-chart {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 200px;
  }
}

.consumption-from-graph-result {
  background-color: #f8f8f8;
  padding: 7px 20px;
  width: 100%;

  p {
    margin-bottom: 0;
  }

  .big-gray-text {
    color: #a5a5a5;
    font-size: 20px;
    line-height: 27px;
    font-weight: 600;

    span {
      color: #606060;
      font-size: 40px;
      line-height: 55px;
    }
  }
}

.next-upadate-time-block {
  text-align: right;

  p {
    font-size: 11px;
    line-height: 15px;
    margin: 15px 0 0;
  }
}

.yaxis-label {
  color: #B6B6B6;
  font-size: 10px;
  font-weight: bold;
  margin-left: 4px;
}

.toolbar button {
  border: 1px solid #000;
  padding: 5px;
  margin: 5px;
}

.graph-xaxis-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.graph-labels {
  display: flex;
  justify-content: space-between;
  flex: 1 1 0px;
}

.graph-labels span {
  font-size: 11px;
  font-weight: bold;
  color: #6E6E6E;
}

.chart-smartmeter-block {
  max-height: 300px;
  margin-right: 30px;
  margin-top: 10px;
}

.graph-arrows {
  width: 30px;
}

.meter-change-row_click {
  display: flex;
  align-items: center;

  img {
    margin: 0 5px;
  }
}

.is-active .meter-change-row_click {
  margin-top: 8px;
}

</style>
